.form-button {
  padding: 6px 9px;
  border-radius: 16px;
  width: 85px;
  background-color: transparent;
  text-align: center;
  font-size: 11px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #ffe178;
  font-weight: 600;
  border: 1px solid #ffe178;
  box-shadow: 0 0 7px rgba(255, 218, 86, 0.25);
  margin: 16px 0;
}

.form-button:hover {
  cursor: pointer;
  border: 1px solid #fff;
  color: #fff;
}

.left {
  float: left;
}

.right {
  float: right;
}

.form-container {
  overflow: scroll;
}

/* #111143 */
