body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Circular Std - Book';
  src: local('Circular Std - Book'), url('./fonts/CircularStd-Book.otf') format('truetype');
}

@font-face {
  font-family: 'Circular Std - BookItalic';
  src: local('Circular Std - BookItalic'), url('./fonts/CircularStd-BookItalic.otf') format('truetype');
}

@font-face {
  font-family: 'Circular Std - Medium';
  src: local('Circular Std - Medium'), url('./fonts/CircularStd-Medium.otf') format('truetype');
}
@font-face {
  font-family: 'Circular Std - MedItalic';
  src: local('Circular Std - MedItalic'), url('./fonts/CircularStd-MediumItalic.otf') format('truetype');
}
@font-face {
  font-family: 'MicroSquare';
  src: local('MicroSquare'), url('./fonts/MicroSquare-Regular.ttf') format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#top-of-site {
  background-color: #121345;
}