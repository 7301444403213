html {
  height: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  font-family: 'Circular Std - Book', sans-serif;
  min-height: 100%;
  height: 100%;
  margin: 0px;
}

.App {
  height: 100%;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  font-family: 'Circular Std - Medium';
}

h2 {
  font-weight: 500;
  font-size: 25px;
  line-height: 33px;
  font-family: 'Circular Std - Medium';
}

h3 {
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.4px;
  margin-bottom: 8px;
}

h4 {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

h5 {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

/* h6 {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
} */

caption {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}

p {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

button, input {
  border: 0;
}

textarea {
  font-family: 'Circular Std - Book';
  font-weight: 400;
}

input:-moz-focusring {
  outline: none;
}

input:focus, textarea:focus, select:focus, button:focus {

  outline: none;

}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

@media only screen and (max-width: 479px) {
  h1 {
    font-size: 36px;
    line-height: 45px;
  }
  h2 {
    font-size: 23px;
    line-height: 30px;
  }
  h5 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}

.animate-on-scroll {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.animate-on-scroll.visible {
  opacity: 1;
  transform: translateY(0);
}
